<template>
  <popupCharge
    :activation="charge"
    v-if="charge"
  />
  <div class="p-10 pr-16 all">
    <div>
      <div
        class="flex items-center"
      >
        <div class="w-full flex text-left text-c36 items-center font-c6 mt-24 pl-4">
          {{ info }}
        </div>
      </div>

      <div class="mt-6 pl-4">
        <stats
          :donnes="statData"
          v-if="statData !== null"
        />

        <fiche class="mt-16" />
      </div>
    </div>
  </div>
</template>

<script>
import back from '../../assets/icons/backBut.svg'
import stats from '../../component/cdevs/caisseDetail/stats'
import fiche from '../../component/cdevs/caisseDetail/ficheCaisse'
import apiroutes from '../../router/api-routes'
import http from '../../plugins/https'
import popupCharge from '../../component/popup/popupCharge'

export default {
  name: "Index",

  components: {
    stats,
    fiche,
    popupCharge
  },

  data () {
    return {
      icons: {
        back,
      },
      info: null,
      statData: null,
      charge: true,
      agency: null
    }
  },

  created () {
    this.info = this.$store.getters.trackAgence
    const user = this.parseJwt(this.$store.getters.token)
    this.getStats(user.sessionAgency)
  },

  methods: {
    retour() {
      this.$router.push('/caisses')
    },

    parseJwt (token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''));
      return JSON.parse(jsonPayload)
    },

    getStats(id) {
      http.post(apiroutes.baseURL + apiroutes.agenceStats, {
        id: id
      })
          .then(response => {
            console.log('stat')
            console.log(response)
            this.statData = response
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: transparent;
}
.all{
  min-height: 100vh;
}
</style>
