<template>
  <div class="">
    <div>
      <div
        class="flex"
      >
        <div class="w-3/5 h-20 flex text-left text-c36 items-center font-c6">
          Fiche de caisse
        </div>

        <div class="w-3/5">
          <div class="flex justify-end w-full">
            <div class="w-1/4 ml-4">
              <bouton
                :icon="icons.calendar"
                icon-height="17"
                icon-width="17"
                height="54px"
                label="Période"
                color="black"
                size="14px"
                radius="5px"
                class="w-full"
                background="white"
                border="1px solid #DCDCE4"
                @info="activePeriode =! activePeriode"
              />
            </div>

            <div class="w-1/4 ml-4">
              <multiselect
                :icon="icons.filter"
                height="54px"
                background="white"
                :icon-width="25"
                value-t="Type"
                :option="optionType"
              />
            </div>

            <div class="w-2/4 ml-4">
              <multiselect
                :icon="icons.filter"
                height="54px"
                background="white"
                :icon-width="25"
                value-t="Moyen de paiement"
                :option="optionMoyen"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="flex justify-end items-center text-c16 text-90 w-full mt-6">
        <div
          class="flex justify-end items-center w-2/4"
          v-if="activePeriode"
        >
          <div>Du</div>
          <datepicker
            height="51px"
            class="w-1/3 ml-4"
            placeholder=""
          />
          <div class="ml-6">
            Au
          </div>
          <datepicker
            height="51px"
            class="w-1/3 ml-4"
            placeholder=""
          />
        </div>

        <bouton
          label="Imprimer"
          :charge="charge"
          class="w-1/5 ml-6"
          @info="imprime"
        />
      </div>

      <div class="mt-10">
        <global-view />
      </div>
    </div>
  </div>
</template>

<script>
import back from '../../../assets/icons/backBut.svg'
import search from '../../../assets/icons/search.svg'
import calendar from '../../../assets/icons/calendar.svg'
import filter from '../../../assets/icons/filter.svg'
import multiselect from '../../../component/helper/form/multiselect'
import datepicker from '../../../component/helper/form/datePicker'
import globalView from '../../../component/cdevs/caisseDetail/globalData'
import bouton from '../../helper/add/button'
import apiroutes from '../../../router/api-routes'
import http from '../../../plugins/https'

export default {
  name: "Index",

  components: {
    globalView,
    multiselect,
    bouton,
    datepicker
  },

  data () {
    return {
      icons: {
        back,
        search,
        calendar,
        filter
      },
      activeClient: false,
      reloadSession: false,
      selectedItem: null,
      user: null,
      optionType: ['Transfère', 'Remise'],
      optionMoyen: ['Mobile money', 'Espèce', 'Chèque'],
      activePeriode: false,
      info: null,
      charge: false
    }
  },

  created () {
    this.info = this.parseJwt(this.$store.getters.token)
  },

  methods: {
    imprime () {
      if (this.info.sessionAgency) {
        this.charge = true
        http.get(apiroutes.baseURL + apiroutes.imprimeFiche + '?id=' + this.info.sessionAgency)
            .then(response => {
              this.charge = false
              console.log(response)
              window.open(response, '_blank');
            })
            .catch(error => {
              this.charge = false
              console.log(error)
            })
      }
    },

    parseJwt (token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''));
      return JSON.parse(jsonPayload)
    },

  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: transparent;
}
</style>
