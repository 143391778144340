<template>
  <div class="w-full">
    <div
        class="w-full flex text-c14 font-c4 text-left items-center item cursor-pointer"
    >
      <div class="w-1/6 text-c13">
        {{ new Date(item.created_at).toLocaleString() }}
      </div>

      <div class="w-1/6 ml-4">
        <span v-if="item.type === 'DEBIT'">-</span>
        {{ item.amount.toLocaleString() }} F
      </div>

      <div class="w-1/6 ml-4">
        <span v-if="item.method === 'CASH'">Espèce</span>
        <span v-if="item.method === 'MOBILE_MONEY'">Mobile Money</span>
        <span v-if="item.method === 'BANK_CHECK'">Chèque</span>
      </div>


      <div class="w-1/6 ml-4">
        <bouton
            label="Débit"
            height="30px"
            color="#F82626"
            background="#FEE9E9"
            radius="10px"
            size="12px"
            v-if="item.type === 'DEBIT'"
            class="w-1/2"
        />
        <bouton
            label="Crédit"
            height="32px"
            color="#00C24E"
            background="#E5F9ED"
            radius="10px"
            size="12px"
            v-if="item.type === 'CREDIT'"
            class="w-1/2"
        />
      </div>

      <div class="w-1/6 ml-4">
        <span v-if="item.fundedBy">{{ item.fundedBy.fullname }}</span>
        <span v-if="item.action === 'DEPOSIT_IN_BANK'">
          <!-- {{item.deposit.person.fullname}} -->
          <span v-if="item.deposit.person.user.type === 'SELL'">{{ agence }}</span>
          <span v-if="item.deposit.person.user.type !== 'SELL'">COMPTABILITÉ</span>
        </span>
        <span v-if="item.action === 'PAID_ORDER'">
          <span v-if="item.receiveBy && item.receiveBy.fullname"> {{ item.receiveBy.fullname }}</span>
          <span v-if="!item.receiveBy">-</span>
        </span>
        <span v-if="item.action === 'TRANSFER_AMOUNT'">
          <span v-if="item.transfer.person">{{ item.transfer.person.fullname }}</span>
        </span>
      </div>

      <div class="w-1/6 ml-4">
        <span v-if="item.action === 'TRANSFER_AMOUNT'">Tranfert entre caisses</span>

        <span v-if="item.action === 'DEPOSIT_IN_BANK'">
          <span v-if="item.deposit">{{item.deposit.receive_bank.bankName}}</span>
        </span>

        <span v-if="item.action === 'FUND_CLIENT'">{{ item.client.name }} {{ item.client.surname }}</span>

        <span v-if="item.action !== 'TRANSFER_AMOUNT' && item.action !== 'DEPOSIT_IN_BANK'">
          <span v-if="item.paidBy">{{ item.paidBy.name }} {{ item.paidBy.surname }}</span>
          <span v-if="!item.paidBy">-</span>
        </span>
      </div>

      <div class="w-1/6 ml-4">
        <span v-if="item.action === 'FUND_CLIENT'">Accompte client</span>
        <span v-if="item.action !== 'FUND_CLIENT'">
          <span v-if="item.type === 'DEBIT'">
            <span v-if="!item.transfer && item.action !== 'DEPOSIT_IN_BANK'">-</span>
            <span v-if="item.transfer">{{ item.transfer.code }}</span>
          </span>

          <span v-if="item.action === 'PAID_ORDER'">
            <span v-if="item.order && item.order.reference">{{ item.order.reference }}</span>
            <span v-if="!item.order">-</span>
          </span>

            <span v-if="item.action === 'DEPOSIT_IN_BANK'">{{item.deposit.num}}</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import entreprise from '../../../assets/icons/entreprise.svg'
import email from '../../../assets/icons/email.svg'
import down from '../../../assets/icons/down.svg'
import bouton from '../../helper/add/button'

export default {
  name: "Index",
  components: {
    bouton,
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    agence: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      icons: {
        entreprise,
        email,
        down
      },
    }
  },

  computed: {
  },

  methods: {
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.item:hover{
  border-left: 3px groove black;
  padding-left: 30px;
}

</style>
