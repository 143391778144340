<template>
  <transfere
    v-if="activeTransfere"
    :activation="activeTransfere"
    :donne="selectedItem"
    @oga="retourTransfere"
  />
  <billetage
    v-if="activeBillet"
    :donnes="selectedItem"
    :activation="activeBillet"
    @oga="retourBillet"
  />
  <cheques
    v-if="activeCheque"
    :donnes="selectedItem"
    :activation="activeCheque"
    @oga="retourCheque"
  />

  <div class="rounded-10 h-auto bg-white w-full pt-8 pb-8">
    <div class="mt-4">
      <div class="all">
        <div class="w-full flex text-90 text-c14 font-c5 text-left mb-6 pl-6">
          <div class="w-1/6">
            Date
          </div>
          <div class="w-1/6 ml-4">
            Montant
          </div>
          <div class="w-1/6 ml-4">
            Moyen de paiement
          </div>
          <div class="w-1/6 ml-4 text-left">
            Type d’opération
          </div>
          <div class="w-1/6 ml-4">
            Auteur
          </div>
          <div class="w-1/6 ml-4">
            Client/Motif
          </div>
          <div class="w-1/6 ml-4">
            Référence
          </div>
        </div>

        <div class="diviser" />

        <div
          v-if="charge"
          class="flex justify-center mt-16"
        >
          <easy-spinner
            type="dots"
            size="70"
          />
        </div>

        <div
          class="text-center text-c18 text-90 mt-10"
          v-if="historiqueData.length === 0 && !charge"
        >
          Aucune donnée pour le moment
        </div>

        <div
          v-for="(item, index) in historiqueData"
          :key="index"
          class="pb-4 pt-1 pl-6"
          :class="{'transfere': item.action === 'TRANSFER_AMOUNT', 'remise': item.action === 'DEPOSIT_IN_BANK'}"
        >
          <div @click="selected(item)">
            <liste
              class="mt-5"
              :item="item"
              :agence="info"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="flex mt-10 justify-end pb-16 pr-10">
      <div class="w-1/3 flex items-center justify-end">
        <div class="text-c16 font-c6">
          Page {{ page }} sur {{ totalPage }}
        </div>
        <icon
          :data="icons.prev"
          height="50"
          width="50"
          class="icone cursor-pointer ml-4"
          original
          @click="prev"
        />
        <icon
          :data="icons.next"
          height="50"
          width="50"
          class="icone cursor-pointer ml-4"
          original
          @click="next"
        />
      </div>
    </div>
  </div>
</template>

<script>
import arrow from '../../../assets/icons/arrow.svg'
import agenda from '../../../assets/icons/agenda.svg'
import down from '../../../assets/icons/down.svg'
import prev from '../../../assets/icons/prev.svg'
import next from '../../../assets/icons/next.svg'
import liste from './liste'
import apiroutes from '../../../router/api-routes'
import http from '../../../plugins/https'
import commandeListe from './commandeListe.json'
import transfere from '../../popup/transfere'
import billetage from '../../popup/billetage'
import cheques from '../../popup/chequeDetail'

export default {
  name: "Index",
  components: {
    liste,
    transfere,
    billetage,
    cheques
  },

  data () {
    return {
      icons: {
        arrow,
        agenda,
        down,
        next,
        prev
      },
      devis: commandeListe,
      charge: true,
      activeTransfere: false,
      activeBillet: false,
      info: null,
      historiqueData: [],
      selectedItem: null,
      activeCheque: false,
      page: 1,
      limite: 20,
      total: 0,
      totalPage: 0,
      agenceId: null
    }
  },

  created () {
    this.info = this.$store.getters.trackAgence
    const user = this.parseJwt(this.$store.getters.token)
    this.agenceId = user.sessionAgency
    this.getHistorique(this.agenceId)
  },

  methods: {
    prev () {
      if (this.page > 1) {
        this.page = this.page - 1
        this.getHistorique(this.agenceId)
      }
    },

    next () {
      if (this.totalPage > this.page) {
        this.page = this.page + 1
        this.getHistorique(this.agenceId)
      }
    },

    getHistorique (id) {
      this.charge = true
      http.post(apiroutes.baseURL + apiroutes.agenceHistoriquePaginate, {
        page: this.page,
        limit: this.limite,
        agencyId: id
      })
          .then(response => {
            this.charge = false
            console.log(response)
            this.historiqueData = response.data
            this.total = response.total
            const page = parseInt(this.total)/parseInt(this.limite)

            if (page > parseInt(page)) {
              this.totalPage = parseInt(page) + 1
            } else {
              this.totalPage = parseInt(page)
            }
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    parseJwt (token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''));
      return JSON.parse(jsonPayload)
    },

    retourTransfere (answer) {
      if (answer) {
        if (this.selectedItem.method === 'CASH') {
          this.activeBillet = true
        } else {
          this.activeCheque = true
        }
      }
      this.activeTransfere = false
    },

    retourBillet (answer) {
      this.activeBillet = answer
      this.activeTransfere = true
    },

    retourCheque(answer) {
      this.activeCheque = answer
      this.activeTransfere = true
    },

    selected (item) {
      this.selectedItem = item

      let origin = window.location.origin
      if (item.action === 'PAID_ORDER') {
        const index = origin + '/detailsCommande?x=' + item.order.id
        window.open(index, '_blank');
        // this.$router.push({ path: '/detailsCommande', query: { x: item.order.id } })
      } else if (item.action === 'FUND_CLIENT') {
        const index = origin + '/clients?x=' + item.client.id
        window.open(index, '_blank');
        // this.$router.push({ path: '/clients', query: { x: item.client.id } })
      } else if (item.action === 'DEPOSIT_IN_BANK') {
        window.open(item.deposit.pdf, '_blank');
        if (item.deposit.justify_url) {
          window.open(item.deposit.justify_url, '_blank');
        }
      } else {
        this.activeTransfere = true
      }

    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.filtr{
  background: rgba(242, 245, 249, 0.5);
  border: 0.5px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 5px;
  height: 36.39px;
}
.compFiltre{
  width: auto;
  min-width: 9.1%;
}
.diviser {
  height: 0.5px;
  background-color: #DDDDDD;
}
.transfere{
  background-color: #FFF5F5;
}
.remise{
  background-color: #F6F5FE;
}
</style>
