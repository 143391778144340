<template>
  <section class="success">
    <popup-base
      v-show="wantToMakeOffer"
      class="popo md:pt-10 md:pb-4 w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto p-5">
        <div
          class="success-container h-full pt-6"
        >
          <div class="text-left flex pl-6 pr-6">
            <div
              class="text-c24 w-4/5"
            >
              Billetage
            </div>
            <div class="w-1/5 flex justify-end">
              <icon
                :data="icons.fermer"
                height="30"
                width="30"
                class="cursor-pointer"
                original
                @click="fermer"
              />
            </div>
          </div>

          <div class="text-c18 font-c4 mt-6 bg-white p-6 pb-12">

            <div class="text-c14 mt-4 text-left bloc p-6">
              <div class="">
                <span class="text-yell">Montant</span>
                <span class="">
                  : {{ donnes.amount.toLocaleString() }} FCFA
                </span>
              </div>
            </div>

            <div class="mt-10">
              <div
                  class="w-full mt-2 flex text-c14 font-c4 text-left items-center cursor-pointer"
                  v-for="(item, index) in donnes.transfer.data"
                  :key="index"
              >
                <div class="w-1/4">
                  {{ item.amount.toLocaleString() }}
                </div>

                <div>X</div>


                <div class="inputo w-1/3 ml-4 bg-f5 h-28 pl-3 pr-3 flex items-center">
                  {{ item.count }}
                </div>

                <div class="ml-2 mr-2">=</div>
                <div class="w-1/3 bg-f5 h-28 flex items-center pl-3 pr-3">
                  <div>{{ (item.amount * item.count).toLocaleString() }}</div>
                </div>
              </div>
            </div>

          </div>

          <div class="flex">
            <div class="w-full">
              <buton
                label="Retour"
                radius="0px 0px 10px 10px"
                height="55px"
                :charge="charge"
                @oga="fermer"
              />
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'
import buton from '../helper/add/button'
import ic_agence from '../../assets/icons/agenceGray.svg'
import fermer from '../../assets/icons/fermer.svg'
import arrow from '../../assets/icons/arrow.svg'
import transfere from '../../assets/icons/transfere.svg'

export default {
  name: 'Success',
  components: {
    PopupBase,
    buton
  },

  props: {
    activation: {
      type: Boolean,
      default: false
    },
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      wantToMakeOffer: false,
      icons: {
        ic_agence,
        fermer,
        arrow,
        transfere,
      },
      error: null,
      charge: false,
    }
  },

  created() {
    this.wantToMakeOffer = this.activation
  },

  methods: {
    fermer () {
      this.$emit('oga', false)
    },

    continuer () {

    },

    logState (answer) {
      this.wantToMakeOffer = answer
      this.fermer()
    },
  }
}
</script>

<style lang="scss"  scoped>
    @import "../../assets/styles/sass/mixin";
    .success {
      z-index: 999;
    }
    .cardinal {
      width: 42%;
    }

    .moyens{
      background-color: #F5F5F5;
    }

    .success-container {
      font-family: $font-default;
      width: 100%;
      background-color: #F5F5F5;
      height: auto;
      margin: auto;
      border-radius: 12px;
    }

    .bloc1{
      border: 0.832299px solid #ECECEC;
      box-sizing: border-box;
      border-radius: 6.65839px;
    }

    .bloc2 {
      border: 0.832299px dashed #CCCCCC;
      box-sizing: border-box;
      border-radius: 4.16149px;
    }

    .bloc{
      min-height: 40px;
      background: #FFF6EF;
      border-radius: 5px;
    }
    @media screen and (max-width: 700px){
      .success-container {
        width: 100%;
        height: 100vh;
      }
      .titre {
        font-size: 18px;
      }
      .bloc1 {
        width: 100%;
      }
      .bloc2 {
        width: 100%;
        font-size: 18px;
      }
      .button {
        width: 100%;
        font-size: 16px;
      }
      .cardinal {
        width: 100%;
      }
    }

    @media screen and (max-width: 1024px) and (min-width: 770px){
      .cardinal{
        width: 65%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }

    @media screen and (max-width: 769px) and (min-width: 701px){
      .cardinal{
        width: 90%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }
    @media screen and (max-width: 320px){
      .cardinal{
        width: 100%;
      }
      .titre{
        font-size: 14px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 15px;
      }
    }
</style>
